// dependencies
import React from "react";

// styles
import css from "./NetchexButton.module.scss";

const NetchexButton = () => (
	<a href="/login/netchex-oauth/" className={`${css.main}`}>
		<div className={css.iconWrapper}>
			<img alt="Netchex" src="/static/img/Integrations/netchex-icon.png" />
		</div>
		<div className={css.label}>Sign in with Netchex</div>
	</a>
);

export default NetchexButton;
