// dependencies
import PropTypes from "prop-types";
import React from "react";

import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// styles
import css from "./ShowError.module.scss";

/**
 *
 * @param {object} props.error - contains a key, code, and message.
 */
const ShowError = (props) => {
	const { error } = props;

	return (
		<div className={css.main}>
			<div className={css.printError}>
				<div className={css.value}>
					<FontAwesomeIcon icon={faExclamationCircle} /> {`${error.message}`}
				</div>
			</div>
		</div>
	);
};

ShowError.propTypes = {
	error: PropTypes.object.isRequired,
};

export default ShowError;
