/*
    Copy pasta.
    https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object

    -- UPDATE --
    I made my own because the other one kept failing.
*/
import { List } from "immutable";

import isArray from "./IsArray";

const Serialize = (obj, encode = true, props = {}) => {
	// track the array types
	const arrayTypes = [];
	const multiKeyedArrays = props.multiKeyedArrays === true;
	const arrayKeySuffix = Object.prototype.hasOwnProperty.call(props, "arrayKeySuffix") ? props.arrayKeySuffix : "[]";

	const parsed = Object.keys(obj)
		.map((objKey) => [objKey, obj[objKey]])
		.filter(([, v]) => v !== undefined)
		.map(([k, v]) => {
			// eslint-disable-line no-unused-vars
			const type = typeof v;
			let key = k;
			let transformedValue = null;

			switch (type) {
				case "object":
					if (isArray(v) || List.isList(v)) {
						key = `${key}${arrayKeySuffix}`;
						transformedValue = v.join(",");

						// update the list of array types
						arrayTypes.push(key);
					} else {
						transformedValue = JSON.stringify(v);
					}
					break;

				default:
					transformedValue = v;
					break;
			}

			return encode ? [encodeURIComponent(key), encodeURIComponent(transformedValue)] : [key, transformedValue];
		});

	let finishedParsed = [];

	if (multiKeyedArrays && arrayTypes.length > 0) {
		parsed.forEach((item) => {
			const keyName = encode ? decodeURIComponent(item[0]) : item[0];
			const isArrayType = arrayTypes.indexOf(keyName) >= 0;

			if (isArrayType) {
				const decodedValue = encode ? decodeURIComponent(item[1]) : item[1];
				const values = decodedValue.split(",");

				values.forEach((val) => {
					const newItem = encode ? [encodeURIComponent(keyName), encodeURIComponent(val)] : [keyName, val];
					finishedParsed.push(newItem);
				});
			} else {
				finishedParsed.push(item);
			}
		});
	} else {
		finishedParsed = parsed;
	}

	return finishedParsed.map(([k, v]) => `${k}=${v}`).join("&");
};

export default Serialize;
