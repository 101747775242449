// dependencies
import PropTypes from "prop-types";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import Button from "../../Components/Button";
import PrimaryLogo from "../../Components/PrimaryLogo";
import getErrorMessage from "../../Config/errorMessages";
// styles
import css from "./InvalidLogin.module.scss";

const InvalidLogin = () => {
	const [searchParams] = useSearchParams();
	const { code } = searchParams;

	let loginUrl = "/login";

	const message = getErrorMessage(code) || "Login failed.";

	return (
		<div data-testid="invalidLoginPage" className={`${css.main}`}>
			<div className={css.mainContent}>
				<div data-testid="loginBox" className={css.loginBox}>
					<div className={css.logoHeader}>
						<div className={css.logoWrapper}>
							<a
								data-testid="performyardLogo"
								className={css.performYardLogo}
								href="https://www.performyard.com/"
							>
								<PrimaryLogo />
							</a>
						</div>

						<div data-testid="pageTitle" className={css.signinCTA}>
							Invalid Login
						</div>

						<div className={css.errorIcon}>
							<FontAwesomeIcon icon={faExclamationTriangle} />
						</div>

						<div data-testid="pageMessage" className={css.invalidMessage}>
							{message} Please return to the{" "}
							<a data-testid="loginLink" href={loginUrl}>
								login page
							</a>{" "}
							and utilize an allowed method after entering your email address.
						</div>

						<div data-testid="loginRedirect" className={css.loginButtonWrapper}>
							<Button href={loginUrl}>Login</Button>
						</div>

						<div data-testid="contactInfo" className={css.contactInfo}>
							You may also contact us with any questions at support@performyard.com or{" "}
							<span>703-870-3710</span>.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

InvalidLogin.propTypes = {
	email: PropTypes.string,
	code: PropTypes.string,
};

InvalidLogin.defaultProps = {
	email: null,
	code: null,
};

export default InvalidLogin;
