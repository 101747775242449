// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cl02hK4BABcHsZad{border-radius:1000px}.FVqJiCbaXcUV7b04{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block}.eWjt1NUxuKgE_WZR{display:flex;flex-direction:column;justify-content:center;align-items:center}.CIk3qSh9ycYb3uAv{animation:aNxMl2A2wJ_WBYTI 3s infinite;animation-iteration-count:infinite}@keyframes aNxMl2A2wJ_WBYTI{0%{background:var(--py-neutral-1)}30%{background:var(--py-neutral-1)}50%{background:var(--py-neutral-1)}80%{background:var(--py-neutral-1)}100%{background:var(--py-neutral-1)}}.O4PThiCtapUZjG7a{margin-bottom:50px;text-align:center}.O4PThiCtapUZjG7a .usEWdH_t6k1KZGHu{display:inline-block;height:70px;width:306px}.O4PThiCtapUZjG7a .usEWdH_t6k1KZGHu .Aekc8oWSmgLMqwe5{display:block;position:absolute;top:0;left:0;width:304px;height:40px}.O4PThiCtapUZjG7a .usEWdH_t6k1KZGHu .Aekc8oWSmgLMqwe5 img{position:absolute;top:0;left:0;height:100%}.O4PThiCtapUZjG7a .XbiLZqWn8gUskqkW{width:100%;text-align:center;font-weight:600;color:#3e90c1}.O4PThiCtapUZjG7a .Jt6PjwQIqFo7uC5h{margin-top:30px;font-size:2em;color:#f1634f}.O4PThiCtapUZjG7a .IJnL2ip4PwQ4M_g3{margin:10px auto 0 auto;width:500px;line-height:1.3em;color:var(--py-neutral-8)}.O4PThiCtapUZjG7a .IJnL2ip4PwQ4M_g3 a{color:#3e90c1}.O4PThiCtapUZjG7a .iUnh3PTMhmMphQvf{margin-top:40px}.O4PThiCtapUZjG7a .vyuzk7oSnUwfJYjf{margin:20px auto 0 auto;width:500px;font-size:.85em;font-style:italic;font-weight:500;line-height:1.3em}.O4PThiCtapUZjG7a .vyuzk7oSnUwfJYjf span{white-space:nowrap}.vl9837uCgBGji3uQ{min-height:100vh;z-index:10;background-color:var(--py-neutral-0);display:flex;flex-direction:column;justify-content:center;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `cl02hK4BABcHsZad`,
	"text-truncate": `FVqJiCbaXcUV7b04`,
	"shaquille": `eWjt1NUxuKgE_WZR`,
	"shimmer": `CIk3qSh9ycYb3uAv`,
	"placeHolderShimmer": `aNxMl2A2wJ_WBYTI`,
	"logoHeader": `O4PThiCtapUZjG7a`,
	"logoWrapper": `usEWdH_t6k1KZGHu`,
	"performYardLogo": `Aekc8oWSmgLMqwe5`,
	"signinCTA": `XbiLZqWn8gUskqkW`,
	"errorIcon": `Jt6PjwQIqFo7uC5h`,
	"invalidMessage": `IJnL2ip4PwQ4M_g3`,
	"loginButtonWrapper": `iUnh3PTMhmMphQvf`,
	"contactInfo": `vyuzk7oSnUwfJYjf`,
	"mainContent": `vl9837uCgBGji3uQ`
};
export default ___CSS_LOADER_EXPORT___;
