// dependencies
import React from "react";

// styles
import css from "./AdpButton.module.scss";

const AdpButton = () => (
	<a href="/login/adp-oauth/" className={`${css.main}`}>
		<div className={css.iconWrapper}>
			<img alt="Sign In with ADP" src="https://talent.performyard.com/static/img/Integrations/adp-logo.png" />
		</div>
		<div className={css.label}>Sign in with ADP</div>
	</a>
);

export default AdpButton;
