// react
import React from "react";
import { createRoot } from "react-dom/client";

// components
import Public from "./Main";

const main = document.getElementById("root");
const root = createRoot(main);

if (main) {
	root.render(<Public />);
}
