// dependencies
import axios from "axios";
import React, { useEffect, useState } from "react";

// components
import ShowError from "../Login/components/ShowError/ShowError";

// hooks
import { usePageMeta } from "../../Hooks/usePageMeta";

import Cookies from "../../../react/utils/Cookies";
// utils
import parseQueryString from "../../../react/utils/ParseQueryString";

import PrimaryLogo from "../../Components/PrimaryLogo";
// config
import getErrorMessage from "../../Config/errorMessages";
// styles
import css from "./MultiFactor.module.scss";

/**
 * Renders the MFA page.
 */

export default function MultiFactor() {
	const prefixURL = "";
	const [mfaCode, setMfaCode] = useState("");
	const [displayError, setDisplayError] = useState(null);
	const queryStringParams = parseQueryString(window.location.search);
	const code = queryStringParams.code ? queryStringParams.code : null;
	const isSession = null;

	/**
	 * Generates a redirect url. It will concat the next param value to the end
	 * of the current origin unless the next param includes an origin that is
	 * the same as the current one. This is to protect our users from a
	 * malicious redirect attack.
	 */
	const getRedirectUrl = () => {
		let nextParam = null;

		try {
			if (queryStringParams.session && queryStringParams.session === "true") {
				return "/extend-session/";
			}

			nextParam = queryStringParams.next ? queryStringParams.next : "/?first=true";
		} catch (ermahgerd) {
			nextParam = null;
		}

		const urlOrigin = window.location.origin;
		const nextOrigin = nextParam.slice(0, urlOrigin.length);

		if (urlOrigin !== nextOrigin) {
			nextParam = urlOrigin + nextParam;
		}

		return nextParam;
	};

	/**
	 * This will run on mount and check if the user already has a valid
	 * session. If true, it will just automatically log the user in.
	 */
	const checkIfValidSession = async () => {
		try {
			await axios.get(`${prefixURL}/api/me/`);
			document.location.href = getRedirectUrl();
		} catch (ermahgerd) {
			// if error, the user is not logged in
		}
	};

	/**
	 * Sends a POST request with mfa code and user email.
	 * it sets the authenticatedUser cookie from the response.
	 * If the mfa authentication was valid it will set it to a valid value
	 * else it will be set to null
	 * It redirects to the redirect page.
	 * @returns {Promise<void>}
	 */

	const authenticateMFA = async () => {
		let userObject = {};

		try {
			const response = await axios.post(
				`${prefixURL}/api/public/authentication/mfa/`,
				{
					mfa_code: mfaCode,
				},
				{ withCredentials: true }
			);

			userObject = JSON.stringify(response.data.authenticatedUser);
			Cookies.set("authenticatedUser", userObject);
			document.location.href = getRedirectUrl();
		} catch (error) {
			const errorResponse = error.response;
			const statusCode = errorResponse.status;
			const errorsList = errorResponse.data.errors;
			const printError = errorsList[0];
			printError.errorCode = statusCode;

			if (errorsList.length > 0) {
				setDisplayError(errorsList[0]);
			}
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		await authenticateMFA();
	};

	// Run this function on component mount
	useEffect(() => {
		checkIfValidSession();
	}, []);

	usePageMeta({
		title: "MFA | PerformYard",
		description: "Multi Factor Authentication to complete Log In",
	});

	return (
		<div className={`${css.main} ${isSession ? css.isSession : ""}`}>
			<div className={css.mainContent}>
				<div data-testid="mfaBox" className={css.mfaBox}>
					<div className={css.logoHeader}>
						<div className={css.logoWrapper}>
							<div className={css.performYardLogo}>
								<PrimaryLogo />
							</div>
						</div>
						<div className={css.signinCTA}>Multi Factor Authentication</div>
					</div>

					<div className={css.formContainer}>
						<div className={css.formContent}>
							<form onSubmit={handleFormSubmit}>
								<label className={css.label}>
									MFA Code:
									<input
										data-testid="mfaInput"
										className={`${css.input} mfa`}
										type="text"
										name="Enter your 6 digit code."
										value={mfaCode}
										onChange={(e) => setMfaCode(e.target.value)}
										placeholder="Enter your 6 digit code."
									/>
								</label>
								<input
									data-testid="mfaSignInButton"
									className={css.button}
									type="submit"
									value="submit"
								/>
							</form>
							{displayError ? <ShowError error={displayError} /> : null}
							{code ? <ShowError error={{ message: getErrorMessage(code) }} /> : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
