// dependencies
import React from "react";

// styles
import css from "./GustoButton.module.scss";

const GustoButton = () => (
	<a href="/login/gusto-oauth/" className={`${css.main}`}>
		<div>
			<div className={css.label}>Sign in with</div>
			<div className={css.iconWrapper}>
				<img alt="Gusto" src="/static/img/Integrations/gusto-icon.png" />
			</div>
		</div>
	</a>
);

export default GustoButton;
