/**
 * With the given props, this will determine wich URL to redirect the user to.
 * It should never redirect the user to a different origin.
 * @param props
 * @returns
 */
export const selectRedirectUrl = (props: {
	next: string | null;
	session: string | null;
	currentOrigin: string;
}): string => {
	const { next, session, currentOrigin } = props;

	// in this case the user is trying to extend their session
	if (session === "true") {
		return "/extend-session/";
	}

	const nextParam = next ?? "?first=true";
	const nextOrigin = (nextParam ?? "").slice(0, currentOrigin.length);

	// If the next URL is not on the same origin as the current URL, redirect
	// to the origin of the current URL.
	if (currentOrigin !== nextOrigin) {
		const originEndsWithSlash = currentOrigin.slice(-1) === "/";
		const nextParamStartsWithSlash = nextParam.slice(0, 1) === "/";

		return currentOrigin + (originEndsWithSlash || nextParamStartsWithSlash ? "" : "/") + nextParam;
	}

	return nextParam;
};
