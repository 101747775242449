// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bdz6KqmB9I7Aob34{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:#d83b01;color:var(--py-neutral-0);font-weight:600;line-height:40px}.bdz6KqmB9I7Aob34 .RORUy08aqUm8vPPh{position:absolute;top:1px;left:1px;width:38px;height:38px;background-color:var(--py-neutral-0);border-radius:1px}.bdz6KqmB9I7Aob34 .RORUy08aqUm8vPPh img{position:absolute;top:7px;left:7px;width:24px;height:24px}.bdz6KqmB9I7Aob34 .kpPsBQo3zbA86gvP{margin-left:50px}.bdz6KqmB9I7Aob34:hover{background-color:rgb(253.8377880184,71.3705069124,3.9622119816)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `bdz6KqmB9I7Aob34`,
	"iconWrapper": `RORUy08aqUm8vPPh`,
	"label": `kpPsBQo3zbA86gvP`
};
export default ___CSS_LOADER_EXPORT___;
