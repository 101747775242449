// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._yl3QtH72nQQFs4V{margin-top:20px;font-size:1em}._yl3QtH72nQQFs4V .Qqa4_JhH3UCE2w6A .TLsydnETfDItqCpP{float:left;color:#f1634f}._yl3QtH72nQQFs4V .Qqa4_JhH3UCE2w6A .R2tLc_5MeLomaHWm{color:#f1634f;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `_yl3QtH72nQQFs4V`,
	"printError": `Qqa4_JhH3UCE2w6A`,
	"icon": `TLsydnETfDItqCpP`,
	"value": `R2tLc_5MeLomaHWm`
};
export default ___CSS_LOADER_EXPORT___;
