// Dependencies
import { useEffect } from "react";

export const usePageMeta = (props: { title?: string; description?: string }) => {
	const { title, description } = props;

	useEffect(() => {
		if (title) {
			document.title = title;
		}

		if (description) {
			const meta = document.querySelector('meta[name="description"]');

			if (meta) {
				meta.setAttribute("content", description);
			}
		}
	}, [title, description]);
};
