/**
 * Removes sensitive data from the top level of an object.
 *
 * @param {Object} event - An event object that is passed to Sentry.
 */
const sanitizeObject = (obj) => {
	const regExsToFilter = [new RegExp(/_token$/), new RegExp(/password/i)];

	for (const key of Object.keys(obj)) {
		for (const regEx of regExsToFilter) {
			console.log(`key is: ${key}, regEx is: ${regEx}`);
			if (regEx.test(key)) {
				delete obj[key];
			}
		}
	}
};

/**
 * A function that is used to sanitize events of sensitive data before sending to Sentry.
 *
 * @param {Object} event - An event object that is passed to Sentry.
 */
const beforeSendEventFunction = (event) => {
	sanitizeObject(event);
	if (event?.contexts) {
		// For each context added, sanitize the content of that context
		for (const contextKey of Object.keys(event?.contexts)) {
			if (event.contexts[contextKey]) {
				sanitizeObject(event.contexts[contextKey]);
			}
		}
	}
	if (event?.tags) {
		for (const tagKey of Object.keys(event?.tags)) {
			if (event.contexts[tagKey]) {
				sanitizeObject(event.contexts[tagKey]);
			}
		}
	}
	return event;
};

export default beforeSendEventFunction;
export { beforeSendEventFunction };
