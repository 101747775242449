// dependencies
import PropTypes from "prop-types";
import React from "react";

// styles
import css from "./SamlButton.module.scss";

const SamlButton = (props) => {
	const { url, label, color } = props;

	return <a href={url} className={`${css.main} ${css[color]}`}>{`Sign in with ${label}`}</a>;
};

SamlButton.propTypes = {
	url: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	color: PropTypes.oneOf(["darkblue"]),
};

SamlButton.defaultProps = {
	color: "darkblue",
};

export default SamlButton;
