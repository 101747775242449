// dependencies
import PropTypes from "prop-types";
import React from "react";

// utils
import Serialize from "../../../react/utils/SerializeObject";

// styles
import css from "./Office365Button.module.scss";

const Office365Button = (props) => {
	const query = Serialize(props.queryParameters);

	return (
		<a href={`/login/microsoft-oauth/?${query}`} className={`${css.main}`}>
			<div className={css.iconWrapper}>
				<img alt="Office 365" src="https://talent.performyard.com/static/img/front/microsoft/office-365.png" />
			</div>
			<div className={css.label}>Sign in with Office365</div>
		</a>
	);
};

Office365Button.propTypes = {
	queryParameters: PropTypes.object,
};

Office365Button.defaultProps = {
	queryParameters: {},
};

export default Office365Button;
