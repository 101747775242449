// dependencies
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// hooks
import { usePageMeta } from "../../Hooks/usePageMeta";

import PrimaryLogo from "../../Components/PrimaryLogo";
// components
import Spinner from "../../Components/Spinner";
// styles
import css from "./ResetEmail.module.scss";

const ResetEmail = () => {
	const [email, setEmail] = useState("");
	const [token, setToken] = useState(null);
	const [ID, setID] = useState("");
	const [fetchingTokenValidity, setFetchingTokenValidity] = useState(true);
	const [resettingEmail, setResettingEmail] = useState(false);
	const [emailReset, setEmailReset] = useState(false);
	const [error, setError] = useState(false);

	const { token: paramsToken, employeeID: paramsEmployeeID } = useParams();

	useEffect(() => {
		axios
			.get(`/api/public/email-tokens/${paramsToken}/${paramsEmployeeID}`)
			.then((response) => {
				setFetchingTokenValidity(false);
				setID(paramsEmployeeID);
				setToken(paramsToken);
				if (response.data.isValid) {
					setEmail(response.data.user.newEmail);
				}
			})
			.catch(() => {
				setError(true);
			});
	}, [paramsToken, paramsEmployeeID]);

	usePageMeta({
		title: "Reset Email | PerformYard",
		description: "Reset your PerformYard email address.",
	});

	const handleConfirm = () => {
		setResettingEmail(true);
		const url = "/api/public/reset-email/";
		const config = {
			token,
			user_id: ID,
		};
		axios
			.post(url, config)
			.then(() => {
				setResettingEmail(false);
				setEmailReset(true);
				setTimeout(() => {
					window.location.href = "/?first=true";
				}, 1000);
			})
			.catch(() => {
				setResettingEmail(false);
				setError(true);
			});
	};

	const handleCancel = () => {
		window.location.href = `/cancel-reset-email/${token}/${ID}`;
	};

	// display spinner when fetching token validity
	if (fetchingTokenValidity) {
		return (
			<div className={css.mainContent}>
				<div className={css.resetEmailCTA}>Set your PerformYard email</div>
				<div className={css.tokenSpinnerWrapper}>
					<Spinner className={css.validTokenSpinner} size={50} />
				</div>
			</div>
		);
	}
	// view if token is valid
	else if (email && !fetchingTokenValidity && !error) {
		return (
			<div className={css.mainContent}>
				<div data-testid="setEmailBox" className={css.setEmailBox}>
					<div className={css.logoHeader}>
						<div className={css.logoWrapper}>
							<a className={css.performYardLogo} href="https://www.performyard.com/">
								<PrimaryLogo />
							</a>
						</div>
						<div className={css.resetEmailCTA}>Confirm your new PerformYard Email:</div>
						{resettingEmail && !error ? (
							<div className={css.tokenSpinnerWrapper}>
								<Spinner className={css.validTokenSpinner} size={50} />
							</div>
						) : null}
						{emailReset ? (
							<div>
								<div className={css.checkIcon}>
									<FontAwesomeIcon icon={faCheckCircle} />
								</div>
								<div>Email changed! You will be redirected shortly.</div>
							</div>
						) : (
							<div>
								<div className={css.newEmail}>{email}</div>
								<div className={css.controls}>
									<button className={css.confirm} onClick={handleConfirm}>
										Confirm
									</button>
									<button className={css.cancel} onClick={handleCancel}>
										Cancel
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
	// view if token is invalid
	else if (!email && !fetchingTokenValidity) {
		return (
			<div className={css.mainContent}>
				<div className={css.logoHeader}>
					<div className={css.logoWrapper}>
						<a className={css.performYardLogo} href="https://www.performyard.com/">
							<PrimaryLogo />
						</a>
					</div>
					<div className={css.resetEmailCTA}>Reset your PerformYard email</div>
					<div>
						<div className={css.setEmailLabel}>
							This one-time reset email link has been used/expired and is no longer valid.
						</div>
						<div className={css.setEmailLabel}>
							Please click <a href={`/edit-employee/${ID}/`}>here</a> to reset your email.
						</div>
					</div>
				</div>
			</div>
		);
	}
	// view if email failed to reset
	else if (error) {
		return (
			<div className={css.mainContent}>
				<div className={css.logoHeader}>
					<div className={css.logoWrapper}>
						<a className={css.performYardLogo} href="https://www.performyard.com/">
							<PrimaryLogo />
						</a>
					</div>
					<div className={css.errorIcon}>
						<FontAwesomeIcon icon={faExclamationTriangle} />
					</div>
					<div className={css.resetEmailError}>
						An error occurred. Please contact{" "}
						<a href="http://support.performyard.com/support/home">support</a> for help.
					</div>
				</div>
			</div>
		);
	}
};

export default ResetEmail;
