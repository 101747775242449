// dependencies
import PropTypes from "prop-types";
import React from "react";

// styles
import css from "./Button.module.scss";

const Button = (props) => {
	const { children, href } = props;

	if (href) {
		return (
			<a className={css.buttonMain} href={href}>
				{children}
			</a>
		);
	}

	return <button className={css.buttonMain}>{children}</button>;
};

Button.propTypes = {
	children: PropTypes.node,
	href: PropTypes.string,
};

Button.defaultProps = {
	href: null,
	children: null,
};

export default Button;
