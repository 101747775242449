const errorCodes = {
	b4d1p: "You are not allowed to access PerformYard from your current IP address; please log in from an approved IP address to continue.",
	xp1r3: "Your session has expired or become invalid.",
	m3thd: "That login method is not currently allowed for your organization.",
	s74ck: "You must be logged in to install the Slack App.",
	c0m3x: "Your company account is no longer active.",
};

const getErrorMessage = (code) => errorCodes[code];

export default getErrorMessage;

export { errorCodes };
