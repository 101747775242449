// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XERk_BEjQvEC8WS1{border-radius:1000px}.ycsBFaIhUzwvPXDF{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block}.LarU31nN1cJcx4Il{display:flex;flex-direction:column;justify-content:center;align-items:center}.CcmZyWCTpM1WFm7f{animation:uDvvo9POD0UIcky3 3s infinite;animation-iteration-count:infinite}@keyframes uDvvo9POD0UIcky3{0%{background:var(--py-neutral-1)}30%{background:var(--py-neutral-1)}50%{background:var(--py-neutral-1)}80%{background:var(--py-neutral-1)}100%{background:var(--py-neutral-1)}}.NuqWTU0OvEEklbNU{box-sizing:border-box;position:relative;border-radius:100px;border-width:3px;border-style:solid;border-color:hsla(0,0%,100%,.1) #fff #fff #fff;animation-name:A1txtELcBZE787mO;animation-duration:1200ms;animation-iteration-count:infinite;animation-timing-function:ease-in-out}@keyframes A1txtELcBZE787mO{from{transform:rotate(0deg)}to{transform:rotate(1080deg)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `XERk_BEjQvEC8WS1`,
	"text-truncate": `ycsBFaIhUzwvPXDF`,
	"shaquille": `LarU31nN1cJcx4Il`,
	"shimmer": `CcmZyWCTpM1WFm7f`,
	"placeHolderShimmer": `uDvvo9POD0UIcky3`,
	"main": `NuqWTU0OvEEklbNU`,
	"spin": `A1txtELcBZE787mO`
};
export default ___CSS_LOADER_EXPORT___;
