// dependencies
import { fromJS, isImmutable } from "immutable";
import Cookies from "js-cookie";

class CookieWrapper {
	constructor(dependency) {
		this.cookieDependency = dependency;
	}

	get(name, defaultValue) {
		const cookie = Cookies.get(name);

		if (cookie === undefined) {
			return defaultValue;
		}

		return cookie;
	}

	getJSON(name, defaultValue = undefined) {
		const cookie = Cookies.getJSON(name);

		if (cookie === undefined) {
			return defaultValue;
		}

		return cookie;
	}

	getImmutable(name, defaultValue = undefined) {
		return fromJS(this.getJSON(name, defaultValue));
	}

	set(key, val) {
		let value = val;
		const name = key;

		if (isImmutable(value)) {
			value = value.toJS();
		}

		Cookies.set(name, value);
	}
}

export default new CookieWrapper(Cookies);
