// dependencies
import React from "react";
import { createBrowserRouter } from "react-router-dom";

import InvalidLogin from "../Pages/InvalidLogin";
// pages
import Login from "../Pages/Login";
import MultiFactor from "../Pages/MultiFactor";
import ResetEmail from "../Pages/ResetEmail";
import SetPassword from "../Pages/SetPassword";

const router = createBrowserRouter([
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/invalid-login",
		element: <InvalidLogin />,
	},
	{
		path: "/mfa-login",
		element: <MultiFactor />,
	},
	{
		path: "/set-password/:token/:userID",
		element: <SetPassword />,
	},
	{
		path: "/reset-email/:token/:employeeID",
		element: <ResetEmail />,
	},
]);

export default router;
