// dependencies
import React from "react";

// styles
import css from "./Spinner.module.scss";

const Spinner = ({ className = "", size = 20 }) => (
	<div className={`${css.main} ${className}`} style={{ height: size, width: size }}>
		<div />
	</div>
);

export default Spinner;
