// Dependencies
import React from "react";
import { useSearchParams } from "react-router-dom";

import AdpButton from "../../../../Components/AdpButton";
import BambooHRButton from "../../../../Components/BambooHRButton";
import GoogleLoginButton from "../../../../Components/GoogleButton";
import GustoButton from "../../../../Components/GustoButton";
import NetchexButton from "../../../../Components/NetchexButton";
import Office365Button from "../../../../Components/Office365Button";
import SamlButton from "../../../../Components/SamlButton";
// Components
import { IAuthenticationMethod } from "../../Login";
// Styles
import css from "./AuthenticationMethods.module.scss";

const AuthenticationMethods = (props: { methods: IAuthenticationMethod[] }) => {
	const { methods } = props;

	const [searchParams] = useSearchParams();

	const oauthQueryParameters = {
		next: searchParams.get("next") ?? undefined,
		companyID: searchParams.get("companyID") ?? undefined,
	};

	return (
		<div data-testid="ssoButtons" className={css.ssoButtons}>
			{methods.map((method) => {
				const { type } = method;

				switch (type) {
					case "google": {
						return (
							<div key={type} className={css.ssoButtonWrapper}>
								<GoogleLoginButton queryParameters={oauthQueryParameters} />
							</div>
						);
					}

					case "office365": {
						return (
							<div key={type} className={css.ssoButtonWrapper}>
								<Office365Button queryParameters={oauthQueryParameters} />
							</div>
						);
					}

					case "bamboohr": {
						return (
							<div key={type} className={css.ssoButtonWrapper}>
								<BambooHRButton />
							</div>
						);
					}

					case "adp": {
						return (
							<div key={type} className={css.ssoButtonWrapper}>
								<AdpButton />
							</div>
						);
					}

					case "netchex": {
						return (
							<div key={type} className={css.ssoButtonWrapper}>
								<NetchexButton />
							</div>
						);
					}

					case "gusto": {
						return (
							<div key={type} className={css.ssoButtonWrapper}>
								<GustoButton />
							</div>
						);
					}

					case "saml": {
						return (
							<div key={method.url} className={css.ssoButtonWrapper}>
								<SamlButton label={method.label} url={method.url} />
							</div>
						);
					}

					default: {
						return null;
					}
				}
			})}
		</div>
	);
};

export default AuthenticationMethods;
