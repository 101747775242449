// dependencies
import React, { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";

import AuthenticationMethods from "./components/AuthenticationMethods";
import ShowError from "./components/ShowError";
import ShowMessage from "./components/ShowMessage";

// hooks
import { usePageMeta } from "../../Hooks/usePageMeta";
import { useAlreadyAuthenticated, useAuthenticationMethods, useRedirectUrl } from "./hooks";

import { loginWithEmailAndPassword, requestPasswordReset } from "./api";

// utils
import Cookies from "../../../react/utils/Cookies";

import PrimaryLogo from "../../Components/PrimaryLogo";
// components
import Spinner from "../../Components/Spinner";
import getErrorMessage from "../../Config/errorMessages";
// styles
import css from "./Login.module.scss";

interface SimpleAuthenticationMethod {
	type: "emailAndPassword" | "google" | "office365" | "bamboohr" | "adp" | "netchex" | "gusto";
}

interface SamlAuthenticationMethod {
	type: "saml";
	url: string;
	label: string;
}

export type IAuthenticationMethod = SimpleAuthenticationMethod | SamlAuthenticationMethod;

/**
 * The login page! Arguably the most important page in the entire application.
 */
const Login = () => {
	// setup background hooks
	useAlreadyAuthenticated();
	const navigate = useNavigate();
	const redirectUrl = useRedirectUrl();
	const [searchParams] = useSearchParams();

	// state goes here.
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [positiveMessage, setPositiveMessage] = useState<string | null>(null);
	const [error, setError] = useState<{ message: string } | null>(null);
	const [emailInput, setEmailInput] = useState("");
	const [passwordInput, setPasswordInput] = useState("");
	const debouncedEmail = useDebounce(emailInput, 200);
	const [isPasswordResetRequesting, setIsPasswordResetRequesting] = useState(false);

	const authenticationMethods = useAuthenticationMethods(debouncedEmail);

	usePageMeta({
		title: "Log in | PerformYard",
		description: "Log in to your PerformYard account",
	});

	// derived state goes here.
	const isSession = !!searchParams.get("session");
	const code = searchParams.get("code") || null;
	const hasEmailAndPassword = authenticationMethods.some((method) => method.type === "emailAndPassword");
	const signInButtonText = isSession ? "Extend Session" : "Sign In";

	// more derived state, but with hooks this time.
	const isEmailValid = useMemo(() => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(emailInput);
	}, [emailInput]);

	// handlers go here.
	const updatePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPasswordInput(event.target.value);
		setPositiveMessage(null);
		setError(null);
	};

	const updateEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailInput(event.target.value);
		setPositiveMessage(null);
		setError(null);
	};

	const authenticateWithPassword = async () => {
		try {
			setIsLoggingIn(true);
			const result = await loginWithEmailAndPassword({
				email: emailInput,
				password: passwordInput,
				companyID: searchParams.get("companyID"),
			});

			const userObject = JSON.stringify(result.authenticatedUser);
			Cookies.set("authenticatedUser", userObject);

			// If the login pipeline contains MFA authentication,
			// route to MFA login page.
			if (result.redirectMfa) {
				navigate("/mfa-login/");
				return;
			}

			// redirect to the redirectUrl
			window.location.href = redirectUrl;
		} catch (err: any) {
			// get the first error message from the response if it exists
			const errorMessage = err.response?.data?.errors?.[0] || null;
			setError(errorMessage);
		} finally {
			setIsLoggingIn(false);
		}
	};

	const handleRequestPasswordReset = async () => {
		try {
			setIsPasswordResetRequesting(true);
			await requestPasswordReset(emailInput);
			setPositiveMessage(
				"Please check your email. If the email address you entered corresponds to an active PerformYard user, an email with instructions will be sent to that address."
			);
		} catch (err) {
			//
		} finally {
			setIsPasswordResetRequesting(false);
		}
	};

	return (
		<div className={`${css.main} ${isSession ? css.isSession : ""}`}>
			<div className={css.mainContent}>
				<div data-testid="loginBox" className={css.loginBox}>
					<div className={css.logoHeader}>
						<div className={css.logoWrapper}>
							<a className={css.performYardLogo} href="https://www.performyard.com/">
								<PrimaryLogo />
							</a>
						</div>

						<div className={css.signinCTA}>Sign in to your PerformYard account</div>
					</div>

					<div className={css.formContainer}>
						<div className={css.formContent}>
							<div className={css.inputsContainer}>
								<div className={css.fieldItemContainer}>
									<label htmlFor="username" className={css.label}>
										Email Address
									</label>
									<input
										autoComplete="username"
										id="username"
										data-testid="emailInput"
										className={`${css.input} email username`}
										name="username"
										type="text"
										value={emailInput}
										onChange={updateEmailInput}
										onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
											if (e.key === "Enter") {
												authenticateWithPassword();
											}
										}}
									/>
								</div>

								{hasEmailAndPassword ? (
									<div className={css.fieldItemContainer} hidden={!hasEmailAndPassword}>
										<label htmlFor="password" className={css.label}>
											Password
										</label>
										<input
											data-testid="passwordInput"
											className={css.input}
											name="password"
											type="password"
											value={passwordInput}
											onChange={updatePasswordInput}
											onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
												if (e.key === "Enter") {
													authenticateWithPassword();
												}
											}}
										/>
									</div>
								) : null}

								{hasEmailAndPassword && !isSession ? (
									<div
										style={{ visibility: isEmailValid ? "visible" : "hidden" }}
										className={css.forgotPassword}
									>
										{isPasswordResetRequesting ? (
											<Spinner className={css.requestPasswordSpinner} size={12} />
										) : (
											<div onClick={handleRequestPasswordReset} className={css.requestPassword}>
												Forgot Password
											</div>
										)}
									</div>
								) : null}

								{error ? <ShowError error={error} /> : null}
								{code ? (
									<ShowError
										error={{
											message: getErrorMessage(code),
										}}
									/>
								) : null}
								{positiveMessage ? <ShowMessage message={positiveMessage} /> : null}

								<div className={`${css.fieldItemContainer}`}>
									<button
										data-testid="signInButton"
										onClick={authenticateWithPassword}
										className={css.button}
										disabled={!hasEmailAndPassword}
									>
										{isLoggingIn ? (
											<div className={css.spinnerWrapper}>
												<Spinner className={css.spinner} size={20} />
											</div>
										) : (
											signInButtonText
										)}
									</button>
								</div>
							</div>

							<AuthenticationMethods methods={authenticationMethods} />
						</div>
					</div>
				</div>

				<div className={css.companyFooter}>
					<a href="https://www.performyard.com/" className={css.footerObject}>
						Home
					</a>
					<a href="http://support.performyard.com/" className={css.footerObject}>
						Support
					</a>
				</div>
			</div>
		</div>
	);
};

export default Login;
