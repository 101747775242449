// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GZ4T5n3uUBgI32tC{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:#84bf3d;color:var(--py-neutral-0);font-weight:600;line-height:40px}.GZ4T5n3uUBgI32tC .gm7ujHwLrKYlYt27{position:absolute;top:1px;left:1px;width:38px;height:38px}.GZ4T5n3uUBgI32tC .gm7ujHwLrKYlYt27 img{position:absolute;top:7px;left:7px;width:24px;height:24px}.GZ4T5n3uUBgI32tC .D3rZyHryweYGlpCV{margin-left:40px}.GZ4T5n3uUBgI32tC:hover{background-color:rgb(151.5714285714,202.4238095238,90.3761904762)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `GZ4T5n3uUBgI32tC`,
	"iconWrapper": `gm7ujHwLrKYlYt27`,
	"label": `D3rZyHryweYGlpCV`
};
export default ___CSS_LOADER_EXPORT___;
