// dependencies
import PropTypes from "prop-types";
import React from "react";

// utils
import Serialize from "../../../react/utils/SerializeObject";

// styles
import css from "./GoogleLoginButton.module.scss";

const GoogleLoginButton = (props) => {
	const query = Serialize(props.queryParameters);

	return (
		<a href={`/login/google-oauth/?${query}`} className={`${css.main}`}>
			<div className={css.iconWrapper}>
				<img alt="Google" src="/static/img/Integrations/google-logo.png" />
			</div>
			<div className={css.label}>Sign in with Google</div>
		</a>
	);
};

GoogleLoginButton.propTypes = {
	queryParameters: PropTypes.object,
};

GoogleLoginButton.defaultProps = {
	queryParameters: {},
};

export default GoogleLoginButton;
