// dependencies
import axios from "axios";
import React from "react";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import ShowError from "./components/ShowError/";

// hooks
import { usePageMeta } from "../../Hooks/usePageMeta";

import PrimaryLogo from "../../Components/PrimaryLogo";
import Spinner from "../../Components/Spinner";
// styles
import css from "./SetPassword.module.scss";

const zxcvbn = require("zxcvbn");
const PasswordValidator = require("password-validator");

// create password validator schema
const schema = new PasswordValidator();
schema
	.is()
	.min(8) // Minimum length 8
	.has()
	.uppercase() // Must have uppercase letter
	.has()
	.lowercase() // Must have lowercase letter
	.has()
	.digits() // Must have digit
	.has()
	.symbols(); // Must have special symbol

const validationMessages = {
	min: "Must have a minimum of 8 characters.",
	uppercase: "Must contain an uppercase character.",
	lowercase: "Must contain a lowercase character.",
	digits: "Must include at least one digit.",
	symbols: "Must include at least one special symbol.",
};

const PageMeta = () => {
	usePageMeta({
		title: "Set Password | PerformYard",
		description: "Set your PerformYard password.",
	});

	return null;
};

const checkPasswordsMatch = (password, repeatPassword) => {
	const passwordsMatch = password === repeatPassword;
	const showPasswordMatchError = repeatPassword.length >= 1;

	return { passwordsMatch, showPasswordMatchError };
};

export default class SetPassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			passwordInput: "",
			repeatPasswordInput: "",
			passwordType: "password",
			userID: "",
			userToken: "",
			validToken: true,
			validPassword: false,
			forgotPasswordRequested: false,
			typingTimeout: 0,
			passwordFeedback: { warning: "", suggestions: [] },
			validationErrors: [],
			fetchingTokenValidity: true,
			sendingNewPasswordReset: false,
			resettingPassword: false,
		};
	}

	componentDidMount() {
		const currentUrl = window.location.href;
		const extracted = currentUrl.match(/set-password\/([^"]+)/);
		const userToken = extracted[1].split("/")[0];
		const userID = extracted[1].split("/")[1];

		this.setState({ userID, userToken }, () => {
			const url = `/api/public/tokens/${this.state.userToken}/${this.state.userID}`;
			axios
				.get(url)
				.then((response) => {
					this.setState({
						validToken: response.data.isValid,
						fetchingTokenValidity: false,
					});
				})
				.catch(() => {
					this.setState({ fetchingTokenValidity: false });
				});
		});
	}

	setPasswordStrength = (passwordInfo) => {
		return this.setState({
			passwordFeedback: {
				warning: passwordInfo.feedback.warning,
				suggestions: [...passwordInfo.feedback.suggestions],
			},
		});
	};

	setPasswordValidation = (validation) => {
		const errors = [];
		validation.forEach((error) => {
			errors.push(validationMessages[error]);
		});

		return this.setState({ validationErrors: errors });
	};

	handlePasswordChange = (e) => {
		const passwordValue = e.target.value;
		if (this.state.typingTimeout) {
			clearTimeout(this.state.typingTimeout);
		}
		this.setState({
			passwordInput: passwordValue,

			typingTimeout: setTimeout(() => {
				this.checkPasswordValidity(passwordValue, this.state.repeatPasswordInput);
			}, 1000),
		});
	};

	handleRepeatPasswordChange = (e) => {
		const repeatPasswordValue = e.target.value;
		/**
		 * Set validPassword to false while we validate, otherwise the
		 * submit button is subject to a race condition where the
		 * passwords don't match, but validPassword hasn't been
		 * update yet due to the setTimeout
		 */
		this.setState({ validPassword: false });

		if (this.state.typingTimeout) {
			clearTimeout(this.state.typingTimeout);
		}

		this.setState({
			repeatPasswordInput: repeatPasswordValue,
			typingTimeout: setTimeout(() => {
				this.checkPasswordValidity(this.state.passwordInput, repeatPasswordValue);
			}, 500),
		});
	};

	checkPasswordValidity = async (password, repeatPassword) => {
		const passwordCheck = zxcvbn(password);
		const validation = schema.validate(password, { list: true });
		await this.setPasswordStrength(passwordCheck);
		await this.setPasswordValidation(validation);

		const { passwordsMatch } = checkPasswordsMatch(password, repeatPassword);

		/**
		 * a password is valid if:
		 * there aren't any validation/password weakness issues
		 * a repeat password has been entered and matches password */
		if (
			schema.validate(password) &&
			!this.state.passwordFeedback.warning.length &&
			passwordsMatch &&
			repeatPassword.length >= 1
		) {
			this.setState({ validPassword: true });
		} else {
			this.setState({ validPassword: false });
		}
	};

	handlePasswordType = () => {
		// toggle value of input type for password (show or hide password)
		if (this.state.passwordInput.length > 0) {
			this.state.passwordType === "password"
				? this.setState({ passwordType: "text" })
				: this.setState({ passwordType: "password" });
		}
	};

	handlePasswordResetSubmit = () => {
		// reset password
		const config = {
			user_id: this.state.userID,
			token: this.state.userToken,
			password: this.state.passwordInput,
		};
		this.setState({ resettingPassword: true });
		const url = "/ajax/set_password/";
		axios
			.post(url, config)
			.then(() => {
				window.location.href = "/?first=true";
			})
			.catch(() => {
				this.setState({ resettingPassword: false });
			});
	};

	handleResendForgotPassword = () => {
		// request new reset password link when token is invalid
		const config = {
			user_id: this.state.userID,
		};
		this.setState({ sendingNewPasswordReset: true });
		const url = "/api/public/authentication/forgot-password/";
		axios
			.post(url, config)
			.then(() => {
				this.setState({
					forgotPasswordRequested: true,
					sendingNewPasswordReset: false,
				});
			})
			.catch(() => {
				this.setState({ sendingNewPasswordReset: false });
			});
	};

	render() {
		const {
			passwordInput,
			repeatPasswordInput,
			passwordType,
			validToken,
			validPassword,
			forgotPasswordRequested,
			userID,
			passwordFeedback,
			validationErrors,
			fetchingTokenValidity,
			sendingNewPasswordReset,
			resettingPassword,
		} = this.state;

		const { passwordsMatch, showPasswordMatchError } = checkPasswordsMatch(passwordInput, repeatPasswordInput);

		return (
			<div className={`${css.main}`}>
				<PageMeta />

				{fetchingTokenValidity || sendingNewPasswordReset ? (
					<div className={css.mainContent}>
						<div className={css.resetPasswordCTA}>Set your PerformYard password</div>
						<div className={css.tokenSpinnerWrapper}>
							<Spinner className={css.validTokenSpinner} size={50} />
						</div>
					</div>
				) : null}

				{validToken && !fetchingTokenValidity && !sendingNewPasswordReset ? (
					// valid token view
					<div className={css.mainContent}>
						<div>
							<div data-testid="setPasswordBox" className={css.setPasswordBox}>
								<div className={css.logoHeader}>
									<div className={css.logoWrapper}>
										<a className={css.performYardLogo} href="https://www.performyard.com/">
											<PrimaryLogo />
										</a>
									</div>

									<div className={css.resetPasswordCTA}>Set your PerformYard password</div>

									<div className={css.setPasswordLabel}>
										Clicking submit will automatically sign you in to PerformYard.
									</div>
								</div>

								<div className={css.imgContainer}>
									<img className={css.img} src={`/avatar/${userID}`} title="" alt="" />
								</div>
								<div>
									<div className={css.formContainer}>
										<div className={css.formContent}>
											{validationErrors.length
												? validationErrors.map((error) => (
														<ShowError
															key={error}
															error={{
																message: error,
															}}
														/>
													))
												: null}
											{passwordFeedback.warning ? (
												<div>
													<ShowError
														error={{
															message: passwordFeedback.warning,
														}}
													/>
													<ul>
														{passwordFeedback.suggestions.map((suggestion) => (
															<li key={suggestion} className={css.suggestion}>
																{suggestion}
															</li>
														))}
													</ul>
												</div>
											) : null}

											<div className={css.inputsContainer}>
												<div className={css.fieldItemContainer}>
													<div className={css.label}>Password</div>
													<input
														autoComplete="password"
														id="password"
														data-testid="passwordInput"
														className={`${css.input} password`}
														name="username"
														type={passwordType}
														value={passwordInput}
														onChange={this.handlePasswordChange}
													/>
												</div>
											</div>
											<div className={css.passwordVisibility} onClick={this.handlePasswordType}>
												{passwordType === "password" ? "Show" : "Hide"} Password
											</div>
											{!passwordsMatch && showPasswordMatchError ? (
												<ShowError
													error={{
														message: "Passwords must match.",
													}}
												/>
											) : null}
											<div className={css.fieldItemContainer}>
												<div className={css.label}>Repeat Password</div>
												<input
													autoComplete="repeatPassword"
													id="repeatPassword"
													data-testid="repeatPasswordInput"
													className={`${css.input} repeatPassword`}
													name="repeatPassword"
													type="password"
													value={repeatPasswordInput}
													onChange={this.handleRepeatPasswordChange}
												/>
											</div>
											{validPassword ? (
												<button
													data-testid="signInButton"
													onClick={this.handlePasswordResetSubmit}
													className={css.button}
												>
													{resettingPassword ? (
														<div className={css.spinnerWrapper}>
															<Spinner className={css.submitSpinner} size={20} />
														</div>
													) : (
														"Submit"
													)}
												</button>
											) : null}
										</div>
									</div>
								</div>
							</div>

							<div className={css.companyFooter}>
								<a href="https://www.performyard.com/" className={css.footerObject}>
									Home
								</a>
								<a href="http://support.performyard.com/" className={css.footerObject}>
									Support
								</a>
							</div>
						</div>
					</div>
				) : (
					// invalid token view
					<div className={css.mainContent}>
						<div className={css.logoHeader}>
							<div className={css.logoWrapper}>
								<a className={css.performYardLogo} href="https://www.performyard.com/">
									<PrimaryLogo />
								</a>
							</div>

							<div className={css.resetPasswordCTA}>Set your PerformYard password</div>
							{!forgotPasswordRequested ? (
								<div>
									<div className={css.setPasswordLabel}>
										This one-time password link has been used/expired and is no longer valid.
									</div>
									<div className={css.setPasswordLabel}>
										Please click <a href="/login">here</a> to return to the PerformYard log in page.
									</div>
								</div>
							) : (
								<div className={css.labelWrapper}>
									<div className={css.checkIcon}>
										<FontAwesomeIcon icon={faCheckCircle} />
									</div>
									<div className={css.setPasswordLabel}>
										Please check your email. If the email address you entered corresponds to an
										active PerformYard user, an email with instructions will be sent to that
										address. Otherwise, no email will be generated.
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}
