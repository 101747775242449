// Dependencies
import { useQuery } from "@tanstack/react-query";
import { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

// Interfaces
import { IAuthenticationMethod } from "./Login";

// Api
import { getAuthenticationMethods, getCurrentUser } from "./api";

// Utils
import { selectRedirectUrl } from "./utils";

/**
 * Checks to see if the user is already authenticated. If they are, it will
 * redirect them to the correct page.
 */
export const useAlreadyAuthenticated = () => {
	const redirectUrl = useRedirectUrl();
	const [searchParams] = useSearchParams();

	const { data } = useQuery({
		queryKey: ["current-user"],
		queryFn: () => getCurrentUser(),
		gcTime: 0, // we don't want to cache this
		retry: false,
	});

	// This comes from the legacy login page - if the company id is present,
	// we want to ignore the current auth state. This allows company switching
	// to function. This could be done a different way, but it would require
	// extensive testing.
	if (searchParams.get("companyID")) {
		return;
	}

	if (data?.me) {
		document.location.href = redirectUrl;
	}
};

/**
 * Generates the redirect URL based on the search params.
 * @returns string
 */
export const useRedirectUrl = () => {
	const [searchParams] = useSearchParams();

	const redirectUrl = useMemo(
		() =>
			selectRedirectUrl({
				next: searchParams.get("next"),
				session: searchParams.get("session"),
				currentOrigin: window.location.origin,
			}),
		[searchParams.get("next"), searchParams.get("session"), window.location.origin]
	);

	return redirectUrl;
};

/**
 * In order to prevent the authentication methods from dissapearing when the
 * user is typing their email address, we store the last authentication methods
 * response in a state.
 * @param email
 * @returns
 */
export const useAuthenticationMethods = (email: string): IAuthenticationMethod[] => {
	const [searchParams] = useSearchParams();
	const lastAuthenticationMethods = useRef<IAuthenticationMethod[]>([]);

	const { data } = useQuery({
		queryKey: ["authentication-methods", email],
		queryFn: () =>
			getAuthenticationMethods({
				email: email,
				companyID: searchParams.get("companyID") ?? undefined,
			}),
		gcTime: 0, // we don't want to cache this
	});

	/**
	 * When the data changes, we update the lastAuthenticationMethods ref iff
	 * the data is defined. This is the mechanism that prevents the
	 * authentication methods from dissapearing when the user is typing their
	 * email address.
	 */
	const authenticationMethods = useMemo(() => {
		if (data) {
			lastAuthenticationMethods.current = data;
		}

		return lastAuthenticationMethods.current;
	}, [data]);

	return authenticationMethods;
};
