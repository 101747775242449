// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BSo55bZTjJoCOMNd{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:#4285f4;color:var(--py-neutral-0);font-weight:600;line-height:40px}.BSo55bZTjJoCOMNd .dpM9o5k17uMaAiy0{position:absolute;top:1px;left:1px;width:38px;height:38px;background-color:var(--py-neutral-0);border-radius:1px}.BSo55bZTjJoCOMNd .dpM9o5k17uMaAiy0 img{position:absolute;top:4px;left:4px;width:30px;height:30px}.BSo55bZTjJoCOMNd .Mp84KaaKLF4AbKX4{margin-left:50px}.BSo55bZTjJoCOMNd:hover{background-color:rgb(104.556,157.888,246.244)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `BSo55bZTjJoCOMNd`,
	"iconWrapper": `dpM9o5k17uMaAiy0`,
	"label": `Mp84KaaKLF4AbKX4`
};
export default ___CSS_LOADER_EXPORT___;
