// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MUFFQKud40CJC45s{border:1px solid #f45d48;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;width:164px;background-color:#f45d48;color:var(--py-neutral-0);font-weight:600;line-height:40px;padding:0 20px}.MUFFQKud40CJC45s ._kgT2fNMQ9QGLOip{position:absolute;top:1px;right:1px;width:76px;height:38px;display:flex;flex-direction:column;justify-content:center;align-items:center}.MUFFQKud40CJC45s ._kgT2fNMQ9QGLOip img{height:24px}.MUFFQKud40CJC45s .WsNsVoTRUUcFZyVr{position:absolute;top:1px;left:1px;width:90px;height:38px;display:flex;flex-direction:column;justify-content:center;align-items:center}.MUFFQKud40CJC45s:hover{background-color:rgb(242.5541237113,71.706185567,47.9458762887);border-color:rgb(225.9201030928,39.5051546392,13.5798969072)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `MUFFQKud40CJC45s`,
	"iconWrapper": `_kgT2fNMQ9QGLOip`,
	"label": `WsNsVoTRUUcFZyVr`
};
export default ___CSS_LOADER_EXPORT___;
