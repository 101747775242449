const parseQueryString = function getJsonFromUrl(query) {
	/*
        Copy and pasted from here:
            https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
    */

	const result = {};
	const cleanedQuery = query.slice(0, 1) === "?" ? query.slice(1) : query;

	cleanedQuery.split("&").forEach((p) => {
		let part = p;

		if (!part) {
			return;
		}

		part = part.split("+").join(" "); // replace every + with space, regexp-free version

		const eq = part.indexOf("=");
		let key = eq > -1 ? part.substr(0, eq) : part;
		const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
		const from = key.indexOf("[");

		if (from === -1) {
			result[decodeURIComponent(key)] = val;
		} else {
			const to = key.indexOf("]", from);
			const index = decodeURIComponent(key.substring(from + 1, to));
			key = decodeURIComponent(key.substring(0, from));

			if (!result[key]) {
				result[key] = [];
			}

			if (!index) {
				result[key].push(val);
			} else {
				result[key][index] = val;
			}
		}
	});

	return result;
};

export default parseQueryString;
