import * as React from "react";

function PrimaryLogo(props) {
	return (
		<img
			src="https://performyard-content.performyard.com/static/img/py-logo/py-logo-and-text-small.png"
			alt="PerformYard"
		/>
	);
}

export default PrimaryLogo;
