// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LWdq_Ny1uO0qvY0g{margin-top:20px;font-size:1em}.LWdq_Ny1uO0qvY0g .WUiBh0v5UlRMzAJ6 .h0KfNmQgB05FwP6M{float:left;color:#2ba31d}.LWdq_Ny1uO0qvY0g .WUiBh0v5UlRMzAJ6 .GjEdcUlOW5IdKmAF{color:#2ba31d;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `LWdq_Ny1uO0qvY0g`,
	"printError": `WUiBh0v5UlRMzAJ6`,
	"icon": `h0KfNmQgB05FwP6M`,
	"value": `GjEdcUlOW5IdKmAF`
};
export default ___CSS_LOADER_EXPORT___;
