// dependencies
import PropTypes from "prop-types";
import React from "react";

import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// styles
import css from "./ShowMessage.module.scss";

/**
 *
 * @param {string} props.message - contains a key, code, and message.
 */
const ShowMessage = (props) => {
	const { message } = props;

	return (
		<div className={css.main}>
			<div className={css.printError}>
				<div className={css.value}>
					<FontAwesomeIcon icon={faCheck} /> {`${message}`}
				</div>
			</div>
		</div>
	);
};

ShowMessage.propTypes = {
	message: PropTypes.string.isRequired,
};

export default ShowMessage;
