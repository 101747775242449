// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CfzItTcOJA8fIcH2{border:none;padding:0;margin:0;outline:none;cursor:pointer;display:block;padding:0 20px;height:40px;font-weight:600;line-height:40px;text-decoration:none;border-radius:2px}.CfzItTcOJA8fIcH2.MZwC751ANhQcJ6S_{background-color:#02344a;color:var(--py-neutral-0)}.CfzItTcOJA8fIcH2.MZwC751ANhQcJ6S_:hover{background-color:rgb(3.0736842105,79.9157894737,113.7263157895)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CfzItTcOJA8fIcH2`,
	"darkblue": `MZwC751ANhQcJ6S_`
};
export default ___CSS_LOADER_EXPORT___;
