// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FOkG9hYgFOw21jUQ{margin-top:30px;text-align:center}.FOkG9hYgFOw21jUQ .Ajyg2aDDeo4YsaNf{display:inline-block;padding:5px 5px;vertical-align:middle}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ssoButtons": `FOkG9hYgFOw21jUQ`,
	"ssoButtonWrapper": `Ajyg2aDDeo4YsaNf`
};
export default ___CSS_LOADER_EXPORT___;
