// Dependencies
import axios, { AxiosResponse } from "axios";

// Interfaces
import { IAuthenticationMethod } from "./Login";

interface CurrentUserResponse {
	errors: string[];
	me: null | {
		aliases: { id: string; name: string }[];
		authorization: { payload: { company_id: string; user_id: string; user_primary_role: string }; token: string };
		defaultCompanyID: string;
	};
}

/**
 * Fetches the current user from the API. The API will read their cookie
 * and identify them. If they are not authenticated, the API will return a 401
 * and this function will catch the error and return null.
 */
export const getCurrentUser = async () => {
	try {
		const res = await axios.get(`/api/me/`).then((res: AxiosResponse<CurrentUserResponse>) => res.data);
		return res;
	} catch (err) {
		return null;
	}
};

export const requestPasswordReset = async (email: string): Promise<any> => {
	return axios
		.post(`/api/public/authentication/forgot-password/`, {
			email: email.trim(),
		})
		.then((res) => res.data);
};

export const getAuthenticationMethods = async (params: {
	email?: string;
	companyID?: string;
}): Promise<IAuthenticationMethod[]> => {
	// handle the no email state
	if (!params.email && !params.companyID) {
		return [];
	}

	return axios
		.post(`/api/public/authentication/methods/`, {
			...(params.email && { email: params.email }),
			...(params.companyID && { companyID: params.companyID }),
		})
		.then((res) => res.data.enabledAuthenticationMethods);
};

interface SuccessfulLoginResponse {
	authenticatedUser: {
		email: string;
		groupID: string;
		id: string;
		managerID: string;
		name: string;
		permissionRole: string;
	};
	errors: string[];
	warnings: string[];
	redirectMfa: boolean;
}

/**
 * Logs in a user with an email and password.
 */
export const loginWithEmailAndPassword = async (props: {
	email: string;
	password: string;
	companyID?: string | null;
}) => {
	const { email, password, companyID } = props;

	return axios
		.post(`/api/public/authentication/login/`, {
			credentials: {
				password: password,
				email: email.trim(),
				...(companyID && { companyID }),
			},
		})
		.then((res: AxiosResponse<SuccessfulLoginResponse>) => res.data);
};
