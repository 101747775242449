// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P4LOmK0lMw1o9hLw{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:50px;height:40px;background-color:#ed1c2e;color:var(--py-neutral-0);font-weight:600;line-height:40px}.P4LOmK0lMw1o9hLw .XBPWREYVJZYv5BPC{position:absolute;top:5px;left:20px;width:50px;height:30px;border-right:1px solid hsla(0,0%,100%,.35)}.P4LOmK0lMw1o9hLw .XBPWREYVJZYv5BPC img{position:absolute;top:5px;left:0px;height:20px}.P4LOmK0lMw1o9hLw .Gf3S6SG_lSkoTl9U{margin-left:80px}.P4LOmK0lMw1o9hLw:hover{background-color:rgb(239.9975510204,65.8024489796,80.8048979592)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `P4LOmK0lMw1o9hLw`,
	"iconWrapper": `XBPWREYVJZYv5BPC`,
	"label": `Gf3S6SG_lSkoTl9U`
};
export default ___CSS_LOADER_EXPORT___;
