// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OYnTE0jjHLw4upAt{margin-top:20px;font-size:1em}.OYnTE0jjHLw4upAt .Fc6L_Lb4ocp0qwfW .aLiNY5llxQoc_Lal{float:left;color:#f1634f}.OYnTE0jjHLw4upAt .Fc6L_Lb4ocp0qwfW .U0Sjb0Grd9A4ZAGw{color:#f1634f;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `OYnTE0jjHLw4upAt`,
	"printError": `Fc6L_Lb4ocp0qwfW`,
	"icon": `aLiNY5llxQoc_Lal`,
	"value": `U0Sjb0Grd9A4ZAGw`
};
export default ___CSS_LOADER_EXPORT___;
