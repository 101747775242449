// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CWlRwfHZjsIiJBhn{border-radius:1000px}.olEOiClCGaYK9Vzk{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block}.FK0iwfxZTCUXBGCw{display:flex;flex-direction:column;justify-content:center;align-items:center}.wUs8B5UfYOkjnJpI{animation:zf0IJZ6AnEyPGQ4s 3s infinite;animation-iteration-count:infinite}@keyframes zf0IJZ6AnEyPGQ4s{0%{background:var(--py-neutral-1)}30%{background:var(--py-neutral-1)}50%{background:var(--py-neutral-1)}80%{background:var(--py-neutral-1)}100%{background:var(--py-neutral-1)}}.ufUqJlaBvMXPfIiE{border:none;margin:0;outline:none;padding:0;text-decoration:none;display:inline-block;cursor:pointer;height:34px;padding:0 20px;background-color:#3e90c1;color:var(--py-neutral-0);font-weight:600;line-height:34px;font-size:.9em;border-radius:3px}.ufUqJlaBvMXPfIiE:hover{background-color:rgb(100.6,166.2,205.4)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `CWlRwfHZjsIiJBhn`,
	"text-truncate": `olEOiClCGaYK9Vzk`,
	"shaquille": `FK0iwfxZTCUXBGCw`,
	"shimmer": `wUs8B5UfYOkjnJpI`,
	"placeHolderShimmer": `zf0IJZ6AnEyPGQ4s`,
	"buttonMain": `ufUqJlaBvMXPfIiE`
};
export default ___CSS_LOADER_EXPORT___;
