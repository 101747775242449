// dependencies
import React from "react";

// styles
import css from "./BambooHRButton.module.scss";

const BambooHRButton = () => (
	<a href="/login/bamboohr-subdomain/" className={`${css.main}`}>
		<div className={css.iconWrapper}>
			<img alt="BambooHR" src="https://talent.performyard.com/static/img/Integrations/bamboohr-circle-icon.png" />
		</div>
		<div className={css.label}>Sign in with BambooHR</div>
	</a>
);

export default BambooHRButton;
