// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RfU3nrtH7w4SOMYx{border:1px solid var(--py-neutral-2);padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:var(--py-neutral-0);color:#26a977;font-weight:600;line-height:40px}.RfU3nrtH7w4SOMYx .vWaFhmr3JW8PYmuB{position:absolute;top:1px;left:1px;width:38px;height:38px}.RfU3nrtH7w4SOMYx .vWaFhmr3JW8PYmuB img{position:absolute;top:7px;left:7px;width:24px;height:24px}.RfU3nrtH7w4SOMYx .QeNIRsYG1ZIAjKxK{margin-left:40px}.RfU3nrtH7w4SOMYx:hover{background-color:var(--py-neutral-1);border-color:var(--py-neutral-3)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `RfU3nrtH7w4SOMYx`,
	"iconWrapper": `vWaFhmr3JW8PYmuB`,
	"label": `QeNIRsYG1ZIAjKxK`
};
export default ___CSS_LOADER_EXPORT___;
